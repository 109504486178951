.modal {
  width: 657px;
  background-color: var(--color-mpr-charcoal);
  border-radius: 2rem;
  padding: 2rem;
  background-image: linear-gradient(
      -3.5deg,
      transparent -7rem,
      var(--color-mpr-red) 1.1rem,
      var(--color-mpr-red) 2rem,
      transparent 2.1rem
    ),
    linear-gradient(184deg, var(--color-mpr-green) 2rem, transparent 2.1rem),
    linear-gradient(
      3deg,
      var(--color-mpr-yellow) 1.1rem,
      var(--color-mpr-yellow) 2rem,
      transparent 2.1rem
    );
}

.modal h3 {
  display: flex;
  font-size: var(--font-size-banner);
  line-height: 2.75rem;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 0;
}

.modal_header {
  font-size: var(--font-size-xl);
  margin-top: 0;
}

.modal_header div {
  display: flex;
  justify-content: space-between;
}

.modal > p {
  display: flex;
  font-size: var(--font-size-small);
  line-height: var(--font-size-line);
  font-weight: 400;
  padding-top: 1rem;
  padding-bottom: 3rem;
}

.modal_buttons {
  color: var(--color-white);
  display: flex;
  flex-flow: wrap;
  padding-bottom: 2rem;
}

.modal_buttons button {
  text-transform: uppercase;
  border: 0.063rem solid var(--color-yc-gray-tint-md);
}

.modal_buttons > *:not(:last-child) {
  margin-right: 1rem;
  border: none;
}

@media (max-width: 730px) {
  .modal {
    width: 100%;
  }
}

@media (max-width: 540px) {
  .modal {
    padding: 1rem;
  }

  .modal > p {
    text-align: center;
  }
}

@media (max-width: 357px) {
  .modal {
    width: 100%;
  }
  .modal h1 {
    font-size: var(--font-size-header);
  }

  .modal > p {
    text-align: center;
    font-size: var(--font-size-micro);
    padding-bottom: 1rem;
  }

  .modal_buttons {
    flex-direction: column;
  }

  .modal_buttons > *:not(:last-child) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
